@import 'variables/colors'

@import './profiler-button'
@import './profiler-overlay'
@import './profiler-data'

.profiler
    &::before
        background: rgba(0,0,0,.6)
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: 501
