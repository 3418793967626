@import 'variables/colors'
@import 'variables/fonts'

.header
    background-color: $corp
    padding: 0 48px
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    position: relative

    font-family: $sans
    font-size: 14px
    color: $ink

.header__logo
    height: 32px
    padding: 14px 0
