// Tip
//
// Markup: tip.twig
//
// Styleguide: authoring.tip

.tip
  &::before,
  &::after
    content: '–'
    display: block
    height: 4px
    width: 50px
    background: black
    overflow: hidden
    margin: 9px auto
  &::after
    margin-top: 40px
  &::before
    margin-bottom: 40px
  position: relative
  text-align: center
  margin: 14px
  padding: .1px

.tip__title
  font-size: inherit
  font-family: inherit
  margin: 1em

.tip__dismiss
  position: absolute
  top: 0
  right: 0
  border: none
  background: transparent
  height: 24px
  line-height: 21px
  font-size: 21px
  padding: 0
  width: 24px
  display: block
  cursor: pointer