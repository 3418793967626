// Dictionary
//
// Styleguide: authoring.dictionary

@import 'variables/colors'
@import 'variables/fonts'
@import 'variables/breakpoints'

$horizontal-padding: 5em
$vertical-padding: 1.5em

$horizontal-padding-mobile: 1em
$vertical-padding-mobile: 1em

#dictionary
  text-align: left
  height: calc(100vh - 60px)
  display: flex
  flex-direction: column

.dictionary__loading
  position: absolute
  bottom: -55px
  left: 50%
  transform: translateX(-50)

.dictionary__container
  display: flex
  flex-direction: column
  overflow: hidden

.dictionary__header
  background: $silver
  padding: 50px $horizontal-padding $vertical-padding
  position: relative

  +narrow
    padding: $vertical-padding-mobile $horizontal-padding-mobile

.dictionary__rim
  display: flex
  justify-content: right
  padding: $vertical-padding $horizontal-padding
  position: relative
  +narrow
    padding: $vertical-padding-mobile $horizontal-padding-mobile

.dictionary__form
  font-family: $serif
  font-size: 23px
  line-height: 30px

  p
    margin: 0

.dictionary__form-title
  display: block
  font-family: $sans
  font-size: 36px
  margin-bottom: 1em
  font-weight: 700
  color: #333

.dictionary__form-slot
  background: #fff
  padding: 20px
  display: flex
  margin-top: 30px
  +narrow
    margin-top: $vertical-padding-mobile * 2

.dictionary__form-query
  min-width: 270px
  font-size: 36px
  line-height: 1.15
  outline: 0
  height: 60px
  background: none
  border: none
  border-left: 4px solid #ffd008
  flex: 1
  padding-left: 12px

  &::placeholder
    color: #969B9F
    opacity: 1

.dictionary__form-button--submit
  background: #ffd008
  border: none
  white-space: nowrap
  padding: 0 28px
  text-transform: uppercase
  font-family: $sans
  font-size: 18px
  font-weight: 800
  letter-spacing: 2px
  text-align: center
  cursor: pointer
  color: $ink
  fill: currentColor

  svg
    vertical-align: middle

  &:disabled
    opacity: .3

.dictionary__form-button--delete
  align-self: center
  padding: 0
  margin: 0 20px
  height: 34px
  width: 34px
  display: inline-block
  font-size: 22px
  line-height: 22px
  text-align: center
  border-radius: 50%
  background: 0 0
  border: none
  outline: 0
  user-select: none
  cursor: pointer
  font-weight: 600

.dictionary__form__message
  line-height: 24px
  letter-spacing: 0.2px
  font-family: $sans
  font-size: 17px
  padding: 15px 0
  display: inline-block
  color: $red

.dictionary__main
  padding: 0 $horizontal-padding
  overflow: auto
  flex: 1 1 auto
  +narrow
    padding: 0 $horizontal-padding-mobile
    overflow: visible

.dictionary__rim--words
  font-size: 14px
  font-weight: 700
  letter-spacing: 1.2px
  text-transform: uppercase

.dictionary__item
  padding: 0 24px
  display: flex
  &:hover
    background: #FFF6D0

  + .dictionary__item
    border-top: 1px solid #ccc

.dictionary__item--word
  font-family: $serif
  font-size: 28px
  font-weight: 400
  line-height: 72px
  flex: 1
  overflow: hidden
  text-overflow: ellipsis

.dictionary__item--delete
  background: none
  border: none
  font-size: 22px
  font-weight: bold
  cursor: pointer

.dictionary__no-entry
  font-size: 28px
  font-weight: 400
  letter-spacing: .2px
  max-width: 684px
  display: inline-block
  margin-top: $vertical-padding
  +narrow
    margin-top: $vertical-padding-mobile
