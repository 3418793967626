@import 'variables/colors'
@import 'variables/fonts'

.marketing-footer__container
    background-color: $silver
    padding: 72px 0

.marketing-footer
    width: 75vw
    margin: 0 auto

.marketing-footer__menu
    font-family: $sans
    font-size: 16px
    letter-spacing: .25px
    line-height: 22px
    color: $ink

.marketing-footer__section
    padding-right: 40px
    &:last-child
        padding-right: 0

    h2
        text-transform: uppercase
        font-weight: 700
        font-size: 16px
        border-bottom: 1px solid $ink-brighter

    ul
        padding: 0
        margin: 0

    li
        margin-left: 0
        margin-top: 18px
        list-style: none

        a
            text-decoration: none
            &:hover
                text-decoration: underline

.marketing-footer__section--social-media
    li
        display: inline-block
        margin-right: 15px
        margin-top: 0

.marketing-footer__logo
    height: 32px
    margin-top: 40px

@media (min-width: 550px)
    .marketing-footer__menu
        display: flex
        flex-wrap: wrap
        justify-content: space-between

    .marketing-footer__section
        flex: 1 0 15%

        h2
            border: none

    .marketing-footer__section--social-media
        flex-basis: 20%
