// Advice
//
// Markup: advice.twig
//
// Styleguide: authoring.advice

$status-size: 32px

.advice__number
  background: #ffd008
  color: black
  font-weight: bold
  height: $status-size
  width: $status-size
  line-height: 32px
  border-radius: $status-size
  font-size: 18px
  display: inline-block
  text-align: center
  letter-spacing: normal
  margin: auto 14px auto 0
  flex-shrink: 0
  .advice--relevance_low &
    background: transparent
    border: 2px solid black
    color: black
    box-sizing: border-box
    line-height: $status-size - 4px

.advice__number
  background: #ffd008

.advice__number--readability
  background: #98a6ff

.advice__number--synonym
  background: #53e25a

.advice__number--plagiary
  background: #ff7d7d
