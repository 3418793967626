
.profiler__button 
    cursor: pointer
    position: absolute
    z-index: 4001
    bottom: 10px
    right: 10px
    background: $blue
    color: #fff
    border-radius: 50%
    padding: 0.5em 1em
    box-shadow: 0 0 3px rgba(0,0,0,0.6)
