$weights: 300, 400, 500, 700, 900
$styles: 'italic', 'normal'

@each $weight in $weights
  @each $style in $styles
    @font-face
      font-family: 'Publico'
      font-style: $style
      font-weight: $weight
      src: url(serif/#{$weight}-#{$style}.woff) format('woff')
      unicode-range: U+0000-024F
      font-display: swap
