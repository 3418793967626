@mixin min
  @media (max-width: 300px)
    @content

@mixin gt-min
  @media (min-width: 301px)
    @content

@mixin narrow
  @media (max-width: 650px)
    @content

@mixin medium
  @media (min-width: 651px) and (max-width: 999px)
    @content

@mixin narrow-or-medium
  @media (max-width: 999px)
    @content

@mixin not-wide
  @media (max-width: 999px)
    @content

@mixin wide
  @media (min-width: 1000px)
    @content

@mixin medium-or-wide
  @media (min-width: 651px)
    @content

@mixin gt-max
  @media (min-width: 1184px)
    @content

@mixin lt-max
  @media (max-width: 1183px)
    @content

@mixin skyscraper
  @media (min-width: 1280px)
    @content

@mixin medium-or-wide-and-lt-max
  @media (min-width: 651px) and (max-width: 1280px)
    @content

@mixin wide-or-max
  @media (min-width: 1000px)
    @content