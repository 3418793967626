// corporate 

$corp-darker: #fbba13
$corp: #ffd008
$corp-brighter: #ffedaa
$corp-brightest: #fff6d0

$corp_alt: #F3B707
$corp_light: #FFDB1A
$calm-color: #44db5e
$warning-color: #ff5f56

// actual colors

$alto: #dedede
$cottonBoll: #e7f1fd
$green: #53e25a
$green-brighter: #8cea9b
$lavender: #98a6ff
$lavender-brighter: #d1d7ff
$mercury: #e2e2e2
$salmon: #ff7d7d
$salmon-brighter: #ffd0cf
$silver: #eff3f5
$tundora: #454444

$red: #e4121e
$blue: #3071A9
$white: #ffffff
$black: #000000

// text colors

$ink: #333
$ink-brighter: #969b9f
$ink-brightest: #dadee0
