@font-face
  font-family: 'cv'
  src: url("icons/linear.woff")
  font-weight: 400
  font-display: swap

@font-face
  font-family: 'cv'
  src: url("icons/flaechig.woff")
  font-weight: 900
  font-display: swap

@font-face
  font-family: 'icons'
  src: url("icons/bold.woff")
  font-weight: 400
  font-display: swap

@font-face
  font-family: 'icons'
  src: url("icons/thin.woff")
  font-weight: 300
  font-display: swap

@font-face
  font-family: 'logos'
  src: url("icons/logos.woff")
  font-display: swap
