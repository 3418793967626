@import 'devices'

$block-height: 50px

@mixin vertical-gap
  +phone
    margin-bottom: 2em
  +tablet
    margin:
      top: calc(5vw - 1em)
      bottom: calc(5vw - 1em)
  +laptop
    margin:
      top: 5vw
      bottom: 5vw
  +desktop-to-imac
    margin:
      top: 128px
      bottom: 128px

@mixin full-grid($cols: 12)
  grid-template-columns: repeat($cols, 1fr)
  column-gap: 5% // 64px