// Pad
//
// Markup: pad.twig
//
// Styleguide: authoring.pad

@import 'variables/colors'
@import 'variables/fonts'
@import 'variables/breakpoints'
@import 'variables/ie'
@import 'variables/firefox'

$pad-height: 50vh

@mixin writing-mode
  .desk--writing-mode &
    @content

@mixin force-scrollbar
  @media (max-width: 1000px)
    padding-right: 7px
    overflow-y: auto
  @media (min-width: 1001px) and (max-width: 1279px)
    padding-right: 14px
  @media (min-width: 1280px)
    padding-right: 21px
  +medium-or-wide
    overflow-x: hidden
    overflow-y: auto
    scrollbar-track-color: transparent
    -ms-overflow-style: scrollbar
    &::-webkit-scrollbar
      background: transparent
      width: 8px
    &::-webkit-scrollbar-thumb
      background: rgba(0,0,0,.25)
      border-radius: 7px
      transition: background .25s
    &:hover::-webkit-scrollbar
      opacity: .1
  +firefox-only
    overflow-y: auto

.pad
  background: white
  transition: font-size .2s
  line-height: 1.5
  height: 100%

  & ::selection
    background: $corp
  & + &
    margin-top: 1rem
  +narrow
    min-height: 0

  [class^="DraftEditor"]
    height: 100%

.pad--selection-is-synonym
  & ::selection
    background: #53e25a

.pad--framed
  padding: 1rem

.pad__writable::before
  content: attr(data-placeholder)
  color: #ccc
  font-size: 2em
  line-height: normal
  display: block
  height: 25vh
  margin-bottom: -25vh
  opacity: 0
  //min-height: $pad-height
  transition: opacity .25s
  pointer-events: none
  .pad--empty > &
    opacity: 1

.pad__writable
  height: 100%
  [contenteditable]
    height: 100%
    +narrow
      +writing-mode
        padding-top: 15px
    +force-scrollbar
    overflow-y: scroll
    +ie-only
      +narrow
        padding-right: 1em

+wide
  .pad--length_short > .pad__writable
    font-size: 56px

  .pad--length_medium > .pad__writable
    font-size: 46px

  .pad--length_long > .pad__writable
    font-size: 40px

  .pad--length_very-long > .pad__writable
    font-size: 32px

  .pad--length_extremely-long > .pad__writable
    font-size: 26px

  .pad--length_overwhelmingly-long > .pad__writable
    font-size: 20px

.pad__caret
  color: $corp
  animation: linear 1s blink alternate infinite
  background: $corp
  display: inline-block
  width: .1em
  height: 1em
  margin: 0 .1em
  overflow: hidden
  vertical-align: text-bottom
  user-select: none

@keyframes blink
  from
    opacity: 1
  to
    opacity: 0

@mixin pad__mark--in-focus
  background-position: center bottom

@mixin pad__mark--in-focus-ie-fallback($color)
  +ie-only
    &.pad__mark--in-focus,
    &:hover,
    &:focus
      background-color: $color

$line-position: 48%

.synonym__mark--highlighted
  background: #53e25a

.pad__mark
  background:
    color: transparent
    image: linear-gradient(to top, #ffd008 $line-position, transparent $line-position)
    size: 100% 300%
    position: center center
  transition: background-position .25s
  &.pad__mark--inline-advice
    cursor: pointer
  &:focus,
  &:hover
    +pad__mark--in-focus
  +pad__mark--in-focus-ie-fallback(#ffd008)

.pad__mark--readability
  background-image: linear-gradient(to top, #d1d7ff $line-position, transparent $line-position)
  +pad__mark--in-focus-ie-fallback(#d1d7ff)

.pad__mark--synonym
  background-image: linear-gradient(to top, #8cea9b $line-position, transparent $line-position)
  +pad__mark--in-focus-ie-fallback(#8cea9b)

.pad__mark--plagiary
  background-image: linear-gradient(to top, #ffd0cf $line-position, transparent $line-position)
  +pad__mark--in-focus-ie-fallback(#ffd0cf)

.pad__mark--correct
  white-space: nowrap
  +pad__mark--in-focus-ie-fallback($calm-color)

.pad__mark--in-focus
  +pad__mark--in-focus

.pad__word-counter__label
  display: inline
  &::after
    content: ':'

.pad__footer
  margin-top: 1em
  display: flex
  justify-content: space-between
  font-size: 14px
  color: #999999

.pad__action
  border: none
  background: transparent
  color: inherit
  font-size: 22.5px
  cursor: pointer
  transition: color .5s
  &:focus
    outline: none
    color: black

.pad__writable
  outline: none
  //min-height: $pad-height
  overflow: auto
  font-size: 18px
  transition: font-size .25s ease-in-out
  font-family: $serif
  +narrow
    padding-right: 0
    min-height: 0

.pad__word-counter
  margin: 0

.pad__word-counter__count
  display: inline
  font-weight: bold
  margin-left: .25em

.pad__word-counter__message
  font-weight: bold
  color: black
  display: inline
  margin: 0

.pad__word-counter--limit-reached
  color: #DB160E

.pad__overflow
  opacity: .45
  cursor: not-allowed
