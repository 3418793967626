@import 'fonts'

@mixin tooltip-wrapper
  position: relative

@mixin tooltip($direction: 'north', $events: 'hover')
  position: absolute
  font-family: $sans
  font-weight: 500
  background: black
  color: white
  padding: 0.6em 1em
  font-size: 10px
  border-radius: 4px
  opacity: 0
  transition: opacity .25s, transform .25s
  pointer-events: none
  @if $direction == 'north'
    bottom: 100%
    left: 50%
    transform: translate(-50%, 20%)
  @if $direction == 'south'
    bottom: 0
    left: 50%
    transform: translate(-50%, 80%)
  @if $direction == 'east'
    left: 100%
    top: 50%
    transform: translate(20%, -50%)
  @if $direction == 'west'
    right: 100%
    top: 50%
    transform: translate(-20%, -50%)

  @each $event in $events
    \:#{$event} > &
      opacity: 1
      @if $direction == 'north'
        transform: translate(-50%, -20%)
      @if $direction == 'south'
        transform: translate(-50%, 80%)
      @if $direction == 'east'
        transform: translate(20%, -50%)
      @if $direction == 'west'
        transform: translate(-20%, -50%)
  &::before
    content: ''
    width: 0
    height: 0
    position: absolute
    border-style: solid
    @if $direction == 'north'
      border-width: 3px 3px 0 3px
      border-color: black transparent transparent transparent
      left: 50%
      top: 100%
      transform: translateX(-50%)
    @if $direction == 'south'
      border-width: 0px 3px 3px
      border-color: transparent transparent black
      left: 50%
      top: 0
      transform: translate(-50%, -100%)
    @if $direction == 'east'
      border-width: 3px 3px 3px 0
      border-color: transparent black transparent transparent
      right: 100%
      top: 50%
      transform: translateY(-50%)
    @if $direction == 'west'
      border-width: 3px 0 3px 3px
      border-color: transparent transparent transparent black
      left: 100%
      top: 50%
      transform: translateY(-50%)
