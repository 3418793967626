// Phase
//
// Idicates the current status of processing
//
// Markup: phase.twig
//
// Styleguide: authoring.phase

.phase
  padding: 17px
  white-space: nowrap
  position: sticky
  top: 0
  z-index: 1
  background: #fffae9
  box-shadow: 0 3px 5px -5px rgba(0,0,0,0.3)

.phase__status
  width: 32px
  display: inline-block
  text-align: center
  line-height: 14px
  vertical-align: middle
  font-size: 24px
  font-weight: 300


.phase__text
  margin-left: 1em
  font-size: 14px
  letter-spaceing: .07em

  display: inline-block
  white-space: normal
  vertical-align: middle

.phase__action 
  margin-top: .7em

.phase__revolver
  display: inline-block
  text-indent: 100%
  white-space: nowrap
  overflow: hidden
  background: url(revolver.svg)
  vertical-align: middle
  height: 26px
  width: 26px
  margin: -7px 3px
  animation: linear .5s revolve infinite
  transition: height .2s, width .2s

@keyframes revolve
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)
