// Handshake
//
// Markup: handshake.twig
//
// Weight: -1
//
// Styleguide: authoring.handshake

@import 'variables/fonts'
@import 'variables/breakpoints'

.handshake
  text-align: center
  font-size: 18px

.handshake__title
  margin-bottom: 10px

.handshake__intro
  margin-bottom: 23px
  display: block
  +medium-or-wide
    font-size: 18px
  +narrow
    font-size: 3.692vw
  font-family: $sans
  line-height: 1.55
  letter-spacing: .01em
  font-weight: 200

.handshake__main
  display: block
  font-size: 28px
  font-weight: 700
  font-family: $serif
  +narrow
    font-size: 6.923vw

.handshake__description
  font-weight: bold
  font-size: 18px
  line-height: 26px

.handshake__main--yellow
  color: #fbcb00

.handshake__subline
  +medium-or-wide
    font-size: 24px
  +narrow
    font-size: 3.692vw
  line-height: 1.9
  font-weight: 700
  margin: 12px 0
  &::before,
  &::after
    content: ' – '

.handshake__subline--short
  +medium-or-wide
    font-size: 24px
  +narrow
    font-size: 3.692vw
  line-height: 1.9
  font-weight: 700
  margin: 12px 0
