@import 'variables/colors'
@import 'variables/fonts'
@import 'variables/breakpoints'

body
  font-family: $sans
  +wide
    font-size: 18px
  +wide
    font-size: 17px
  +narrow
    font-size: 16px
  line-height: 1.6
  color: $ink
  -webkit-font-smoothing: antialiased
  -ms-overflow-style: -ms-autohiding-scrollbar
  scroll-behavior: smooth
  --empathy: italic
  quotes: "„" "“" "‚" "‘"

em
  font-style: var(--empathy)

button
  font-family: inherit

a
  color: inherit

h1
  font-size: 36px
  line-height: 1.2
  font-weight: 700
  margin-top: 0

hr
  border:
    top: 0
    right: 0
    left: 0
    bottom: 1px solid

summary
  list-style: none

\::-webkit-details-marker
  display: none

h2
  font-size: 18px
  line-height: 1.555
  font-weight: 700
  margin-bottom: 20px
  margin-top: 30px

h3
  font-size: 14px
  line-height: 1.555
  font-weight: 700

blockquote
  background: rgba(255,255,255,.2)
  padding: 1em 2em
  margin: 0

svg
  max-height: 100%

table
  border-collapse: separate
  border-spacing: 0 2px
  width: 100%

ol[type="a"]
  counter-reset: alphabet
  list-style: none
  > li::before
    counter-increment: alphabet
    content: counter(alphabet, lower-alpha) ')'
    position: absolute
    line-height: inherit

tr
  background: rgba(255,255,255,.2)

caption
  font-weight: bold

caption,
td,
th
  padding: 15px
  text-align: left

img
  max-width: 100%
  &[height]
    height: auto

mark
  background: $corp
  outline: 1px solid rgba(255,255,255,.5)

kbd
  display: inline-block
  font-family: $sans
  padding: 3px 5px
  line-height: 10px
  font-weight: bold
  vertical-align: middle
  border: solid 1px
  border-radius: 3px
  box-shadow: inset 0 -1px 0

dd
  margin-left: 1.2em

ul,
ol
  padding-left: 1.2em

pre,
code
  font-family: $mono
