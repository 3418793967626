// Toast
//
// Markup: toast.twig
//
// Styleguide: authoring.toast

.toast
  z-index: 10
  border-radius: 4px
  text-align: center
  font-size: 14px
  letter-spacing: .07em
  line-height: 1.3
  background: #EFEFEF
  font-weight: 500
  margin-bottom: 6px
  position: absolute
  padding: 14px 37px
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.3)
  cursor: default

.toast--padded
  padding: 20px 37px

.toast--type_success
  background: #BAEDC3
  > .toast__pointer
    color: #BAEDC3

.toast--type_warning
  background: #F9C2C0
  > .toast__pointer
    color: #F9C2C0

.toast__pointer
  color: #EFEFEF
  position: absolute
  top: 100%
  left: 50%
  transform: translateX(-50%)
  line-height: 0
  text-shadow: 0 2px 4px rgba(0,0,0,.3)
  font-size: 1.5em
  margin-top: -1px

.toast__close
  right: 0
  top: 0
  border: 0
  background: transparent
  position: absolute
  line-height: 17px
  font-size: 16px
  cursor: pointer
  outline: none

.toast__bottom
  margin-top: 1em

.toast--type_ink
  border-radius: 6px
  letter-spacing: .06em
  line-height: 20px
  background-color: #333333
  color: #ffffff
  font-size: 14px
  font-weight: normal
  padding: 14px 20px
  .toast__close
    font-size: 14px
    color: #eeeeee
    right: 0
    top: 0
    border: 0
    position: absolute
    cursor: pointer
  a
    color: #ffd008
    font-weight: bold
  .toast__pointer
    color: #333333
    position: absolute
    top: 100%
    left: 50%
    transform: translateX(-50%)
    line-height: 0
    text-shadow: 0 2px 4px rgba(0,0,0,.3)
    font-size: 1.5em
    margin-top: -1px

.toast__container
  padding: 0.5em 5em
  .toast--type_ink
    padding: 0.5em 3em 0.5em 1em
    display: inline-block
    text-align: left
