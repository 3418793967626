@mixin because-ie
  @content

@mixin ie-only
  @media (-ms-high-contrast: active), (-ms-high-contrast: none)
    @content

@mixin ie-grid
  +ie-only
    margin:
      left: 45px
      right: 45px
