// Advice
//
// Styleguide: ads.skyscraper

@import 'variables/fonts'
@import 'variables/breakpoints'

.skyscraper
  height: 600px
  width: 160px
  display: none
  +skyscraper
    display: block
  +gt-max
    width: 300px

.skyscraper__label
  +ad-label