@import 'variables/breakpoints'
@import 'variables/metrics'
@import 'variables/colors'

.knob
  background-color: $ink
  color: white
  line-height: $block-height
  box-sizing: border-box
  padding: 0 1.5em
  // border-radius: .13em
  font-size: 15px
  text-transform: uppercase
  letter-spacing: .05em
  font-weight: 700

  // for <a>
  white-space: nowrap
  display: inline-block
  text-decoration: none
  text-align: center

  // for <button>
  border: 0
  font-family: inherit
  cursor: pointer

  box-shadow: 0 0 0 1px inset

  &:focus,
  &:hover
    outline: none
    background: transparent
    color: $ink

    // for <a>
    text-decoration: none

  &[disabled]
    opacity: .5

.knob--secondary
  background: white
  color: black

.knob--primary
  background: $corp
  color: black
  box-shadow: none

  &:focus,
  &:hover
    background: $corp-brighter
    color: black

.knob--small
  font-size: 12px
  line-height: 32px
  padding: 0 15px

.knob--smaller
  font-size: 11px
  line-height: 2
  letter-spacing: 1.87px
  font-weight: 500
  padding: 0 10px
  display: none

  @media (min-width: 651px)
    display: inline-block
