@import 'variables/colors'
@import 'variables/fonts'

.marketing-headline
    text-align: center
    font-family: $serif

    h1, h2
        margin: 0
        padding: 0

    h1
        font-size: 56px
        line-height: 70px
        font-weight: 800
        margin-bottom: 20px
    h2
        font-size: 19px
        line-height: 30px
        font-weight: normal
        letter-spacing: .27px

.marketing-desk
    margin: 40px 10px 50px
    max-width: 1440px
    height: 780px
    box-shadow: 0 2px 13px 0 rgba(66, 71, 87, 0.27)
    border-radius: 12px 12px 0
    padding-top: 28px
    background-color: $corp

    iframe
        background-color: $white

    @media (min-width: 768px)
        margin: 75px 30px 50px

    @media (min-width: 1024px)
        margin: 75px auto 50px

.marketing-main
    display: flex
    margin-top: 100px

    &__content
        flex: 1 1 auto
        margin: 0 20px

.marketing-ads--skyscraper
    display: none

    @media (min-width: 1024px)
        display: block
        padding: 10px
