@import 'variables/fonts'

// <span class="fract"><sup>1</sup>⁄<sub>2</sub></span>  

.fract
  font-size: 10px
  font-weight: 500

// <a class="rule-ref">D123</a>

.rule-ref
  color: white
  background: #E4121E
  padding: .26em .3em
  margin: 0 .09em
  line-height: 1em
  font-weight: bold
  display: inline-block
  text-decoration: none
  font-style: normal
  font-family: $sans
  font-size: .55em
  vertical-align: .3em

// <span class="endorsed">Shogun</span>

.endorsed
  background-color: #FECF2F
  padding: 0 .25em
  display: inline-block
  font-weight: normal
  border-radius: 5px
  border: 1px solid white

// <span class="nowrap">Donaudampfschiffahrtsgesellschaftskapitän</span>

.nowrap
  white-space: nowrap