// Rim
//
// Displays additional info and functions on the bottom of the pad.
//
// Markup: rim.twig
//
// Styleguide: authoring.rim

@import 'variables/breakpoints'
@import 'variables/colors'
@import 'variables/fonts'
@import 'variables/tooltip'

.rim
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 14px
  color: #999999
  background: white
  height: 44px
  padding-bottom: 15px

  +medium-or-wide
    padding-bottom: 35px

.rim__word-counter
  margin: 0
  color: $ink

.rim__word-counter__data
  display: inline
  margin-left: 0

.rim__word-counter__message
  font-weight: bold
  color: black
  display: inline
  margin: 0

.rim__word-counter--limit-reached
  color: #DB160E

.rim__word-counter__label
  display: inline
  &::after
    content: ':'

.rim__actions
  display: flex
  align-items: center
  align-self: flex-end
  white-space: nowrap
  line-height: 14px
  margin-bottom: 7px

.rim__action
  border: none
  background: transparent
  color: $ink
  line-height: 26px
  font-size: 26px
  margin: 0 5px
  padding: 5px
  cursor: pointer
  transition: color .5s
  font-weight: 300
  transition: width .25s, padding .25s, opacity .25s, margin-left .25s
  display: inline-block
  +tooltip-wrapper
  &:focus
    outline: none
    color: black
  &[disabled]
    +medium-or-wide
      opacity: .5
    +narrow
      display: none
      width: 0
      padding: 0
      opacity: 0
      margin-left: -.1em

.rim__one_click_actions
  display: flex
  flex-direction: column
  align-items: flex-end

.rim__word-counter__count
  display: inline-block
  font-weight: bold
  text-align: center

@for $i from 1 through 5
  .rim__word-counter__count--digits_#{$i}
    min-width: .7ex * $i

.rim__action__tooltip
  +tooltip
