.profiler__data
    padding: 0 15px 15px

    caption 
        background: $corp
        margin-bottom: 1em

    th,
    td 
        border-bottom: 1px dotted lightgray
        padding: 0 5px 0 0
    th
        position: relative
        left: 15px


.profiler__data-opener 
    position: absolute
    left: 0
    top: 5px
    font-size: 10px

.profiler__duration-indicator
    height: 1em
    background: $corp
        