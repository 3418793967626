@mixin theinhardt($style, $weight)
  @font-face
    font-family: Theinhardt
    font-style: $style
    font-weight: $weight
    src: url(sans/#{$weight}-#{$style}.woff) format('woff')
    font-display: swap

+theinhardt(normal, 400)
+theinhardt(normal, 500)
+theinhardt(normal, 700)
+theinhardt(italic, 400)
