@import 'variables/colors'
@import 'variables/fonts'

.navigation
    font-family: $sans
    font-size: 17px
    font-weight: 500
    height: 100%
    list-style: none
    margin: 0
    padding: 0

    a, span
        text-decoration: none
        transition: background .25s
        cursor: pointer

.menu-item
    border-bottom: 2px solid #fff

    > a, > span
        display: block
        padding: 15px 30px
        font-size: 18px

    &:hover, a:hover
        background-color: $silver

.menu-item--has-children
    position: relative

    > a, > span
        color: $ink-brighter
        fill: currentColor
        padding-bottom: 0

    &:hover .navigation--sub
        display: block

.navigation--sub
    min-width: 100%
    left: 0
    background-color: $silver
    margin: 0
    height: auto

    .menu-item
        border-bottom: none

        > a, > span
            padding: 15px 71px

            &:hover
                background-color: #e2e2e2

.account-indicator
    display: flex
    align-items: center
    padding: 0

    &__title
        margin: 0 15px 0 5px
        order: 1
    .user-status 
        order: 2
    img
        order: 0

.main-navigation-mobile
    position: fixed
    width: 0
    right: 0
    top: 0
    bottom: 0
    z-index: 300
    display: flex
    flex-direction: column
    background: $silver
    transition: width 0.25s ease-in
    overflow: auto

    &--visible
        width: 100vw

    &__header
        position: sticky
        top: 0
        background: $silver
        border-bottom: 4px solid #fff
        padding: 15px 30px
        text-align: right
        z-index: 401

    .alert-space
        display: block
        padding: 15px 30px

        a
            text-align: center
            display: block

    /* sorting header items */
    .alert-space
        order: 0
    nav:nth-of-type(1)
        order: 1
    nav:nth-of-type(2)
        order: 2

.main-navigation-toggle
    position: absolute
    top: 50%
    transform: translateY(-50%)
    right: 48px
    font-size: 34px
    font-weight: 400
    padding: 0
    border: none
    background: transparent
    cursor: pointer

    &--close
        position: static
        transform: translateY(0)
        font-size: 26px
        font-weight: 700

    &:focus
        background: transparent
        outline: none

@media (min-width: 768px)
    .main-navigation-mobile--visible
        width: 45vw

@media (min-width: 1024px)

    .main-navigation-mobile
        display: flex
        flex-direction: row
        position: static
        width: auto
        overflow: visible
        background: transparent

        &__header
            display: none

        .alert-space
            align-items: center
            display: flex
            padding: 0 1em 0 0
            order: 1

    .main-navigation-toggle
        display: none

    .navigation
        display: flex
        margin-right: 16px

    .menu-item
        border: none

        > a, > span
            display: flex
            height: 100%
            align-items: center
            padding: 0 16px
            white-space: nowrap

    .menu-item--has-children > span
        color: $ink

        &:after
            content: url("../icons/triangle-down.svg")
            position: relative
            font-weight: 700
            margin-left: 3px
            top: 3px

    .navigation--sub
        display: none
        position: absolute
        margin: 0

        .menu-item > a, .menu-item > span
            padding: 15px 16px

        .menu-item--right &
            left: auto
            right: 0

    .account-indicator__title
        display: none
        order: 0

        &--username
                display: flex
                align-items: center
    
    .account-indicator .user-status
        order: 0
