$title: 'cv', 'Publico Headline', 'icons', serif
$serif: 'cv', 'Publico', 'icons', serif
$sans: 'cv', Theinhardt, 'icons', sans-serif
$mono: 'cv', Plex, 'icons', monospace

@mixin title-font
  font-family: $title
  font-weight: 800

@mixin unerdline-fancy
  background-image: linear-gradient(to top,$corp 10%,transparent 10%)

@mixin button-font
  font-size: 16px
  text-transform: uppercase
  letter-spacing: .05em
  font-weight: 500

@mixin ad-label
  font-size: .5em
  color: #969b9f